<template>
  <header class="bar">
    <button class="sidebar-toggle" @click="toggleSidebar" v-if="isMobile">
    ☰
    </button>
    <div class="removerr-title">Removerr</div>
    <div class="search-bar-wrapper">
      <input type="text" class="search-bar" placeholder="Search..." v-model="searchQuery" @input="filterItems">
    </div>


    <div class="dropdown-wrapper" v-if="!isMobile">
      <div class="dropdown-title">Sorting: </div>
      <select
        class="sorting dropdown-menu"
        v-model="selectedSorting"
        @change="onSortingChange"
      >
        <option value="0">None</option>
        <option value="1">Score asc.</option>
        <option value="2">Score desc.</option>
        <option value="3">Votes desc.</option>
      </select>
    </div>
    <div class="dropdown-wrapper" v-if="!isMobile">
      <div class="dropdown-title">Filtering: </div>
      <select
        class="filtering dropdown-menu"
        v-model="selectedFiltering"
        @change="onFilteringChange"
      >
        <option value="0" selected>None</option>
        <option value="1">sas</option>
      </select>
    </div>

    <div
      class="sidebar"
      :class="{ 'is-open': isSidebarOpen }"
      @click.self="toggleSidebar"
    >
      <div class="sidebar-content">
        <div class="dropdown-wrapper">
          <div class="dropdown-title">Sorting:</div>
          <select
            class="sorting dropdown-menu"
            v-model="selectedSorting"
            @change="onSortingChange"
          >
            <option value="0">None</option>
            <option value="1">Score asc.</option>
            <option value="2">Score desc.</option>
            <option value="3">Votes desc.</option>
          </select>
        </div>
        <div class="dropdown-wrapper">
          <div class="dropdown-title">Filtering:</div>
          <select
            class="filtering dropdown-menu"
            v-model="selectedFiltering"
            @change="onFilteringChange"
          >
            <option value="0" selected>None</option>
            <option value="1">sas</option>
          </select>
        </div>
      </div>
    </div>

    <a class="logout" href="javascript:void(0)" @click="logout">💣</a>

    <!-- <div class="dropdown-wrapper">
      <div class="dropdown-title">Sorting: </div>
      <select class="sorting dropdown-menu" v-model="selectedSorting" @change="onSortingChange">
        <option value="0">None</option>
        <option value="1">Score asc.</option>
        <option value="2">Score desc.</option>
        <option value="3">Votes desc.</option>
      </select>
    </div>
    <div class="dropdown-wrapper">
      <div class="dropdown-title">Filtering: </div>
      <select class="filtering dropdown-menu" v-model="selectedFiltering" @change="onFilteringChange">
        <option value="0" selected>None</option>
        <option value="1">sas</option>
      </select>
    </div>
    <a class="logout" href="javascript:void(0)" @click="logout">💣</a> -->
  </header>
  <div class="root">
    <div class="dashboard-container">
      <div class="media-container" ref="media_container">
        <div v-for="(media, index) in filtered_items" :key="media.name" :id="index" class="media-item-wrapper">
          <div class="media-item">
            <div class="media-poster-wrapper">
                <a class="media-link" href="javascript:void(0)">
                  <img v-lazy="media.image_url" @click="navigateToMedia(media.id)">
                  <div class="media-buttons">
                    <button href="javascript:void(0)" @click="vote(media.id, 1)">⇡</button>
                    <button href="javascript:void(0)" @click="vote(media.id, -1)">⇣</button>
                  </div>
                </a>
            </div>
            <div class="media-metadata">
              <div class="media-title">{{ media.title }}</div>
              <div class="media-score">Score: {{ media.score }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import axios from 'axios';

export default {
  data() {
    return {
      dataVar: null,
      status: false,
      statusMessage: '',
      statusClass: '',
      searchQuery: '',
      items: [],
      filtered_items: [],
      selectedSorting: 0,
      selectedFiltering: 0,
      isSidebarOpen: false,
      isMobile: window.innerWidth <= 768
    };
  },
  created() {
    const savedSorting = localStorage.getItem('selectedSorting');
    if (savedSorting) {
      this.selectedSorting = savedSorting;
    }
    const savedFiltering = localStorage.getItem('selectedFiltering');
    if (savedFiltering) {
      this.selectedFiltering = savedFiltering;
    }
    this.fetchData();
    this.filtered_items = this.items;
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    this.saveScrollPosition();
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },
    restoreScrollPosition() {
      const scrollY = localStorage.getItem('scrollY');
      const container = document.querySelector('.media-container');
      if (container) {
        container.scrollTop = scrollY;
        console.log(container.scrollHeight);
        console.log(scrollY, container.scrollTop);
      }
    },
    saveScrollPosition() {
      const container = document.querySelector('.media-container');
      localStorage.setItem('scrollY', container.scrollTop);
    },
    onSortingChange() {
      localStorage.setItem('selectedSorting', this.selectedSorting);
      this.fetchData();
    },
    onFilteringChange() {
      localStorage.setItem('selectedFiltering', this.selectedFiltering);
      this.fetchData();
    },
    navigateToMedia(id) {
      this.$router.push(`/media/${id}`);
    },
    filterItems() {
      const query = this.searchQuery.toLowerCase();
      this.filtered_items = this.items.filter((item) => 
        item.title.toLowerCase().includes(query)
      );
    },
    fetchData() {
      const params = new URLSearchParams();
      params.append('size', 0);
      params.append('sort', this.selectedSorting);
      params.append('filter', this.selectedFiltering);

      const router = useRouter();
      axios.get(`/api/getItems`, {
      withCredentials: true,
      params: params
      })
      .then((data) => {
        this.status = true;
        this.items = data.data;
        this.filtered_items = this.items;
      })
      .catch((error) => {
        this.status = true;
        this.dataVar = error.response.data;
        if (this.dataVar) {
          if (this.dataVar.message.includes("Error: Your session expired")) {
            this.$cookies.remove("session");
            router.push({ name: "LoginForm" });
          }
        }
      });
    },
    logout() {
      this.$cookies.remove("session");
      this.$router.push({ name: "LoginForm" });
    },
    vote(id, val) {
      axios.post(`/api/media/${id}/${val}`, {
        withCredentials: true
      })
      .then(() => {
        this.updateVotes(id);
      })
      .catch((error) => {
        const router = useRouter();
        this.status = true;
        this.dataVar = error.response.data;
        if (this.dataVar.length > 0) {
          if (this.dataVar.message.includes("Error: Your session expired")) {
            this.$cookies.remove("session");
            router.push({ name: "LoginForm" });
          }
        }
      });
    },
    updateVotes(id) {
      axios.get(`/api/media/${id}/votes`, {
        withCredentials: true
      })
      .then((data) => {
        for (var i = 0; i < this.filtered_items.length; i++) {
          if (this.filtered_items[i].id == id) {
            this.filtered_items[i].score = data.data.val;
          }
        }
      })
      .catch((error) => {
        const router = useRouter();
        this.status = true;
        this.dataVar = error.response.data;
        if (this.dataVar.length > 0) {
          if (this.dataVar.message.includes("Error: Your session expired")) {
            this.$cookies.remove("session");
            router.push({ name: "LoginForm" });
          }
        }
      })
    }
  }
};
</script>

<style scoped>
.removerr-title {
  flex-grow: 1;
  font-size: var(--normal-text-size);
  color: var(--font-color);
  font-weight: 600;
  display: flex;
  margin-right: auto;
  align-items: center;
}

.search-bar-wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.search-bar {
  min-width: 10px;
  max-width: 400px;
  padding: 6px;
  background-color: rgb(40, 40, 40);
  border: none;
  border-radius: 3px;
  border-bottom: solid 1px white;
  color: var(--font-color);
  font-size: var(--normal-text-size);
}

.search-bar:focus-visible {
  outline: 0;
  border: none;
}

.dashboard-container {
  display: flex;
  flex-direction: column;
}

.media-container {
  max-width: 100vw;
}

* {
  box-sizing: border-box;
}

.bar {
  display: flex;
  position: fixed;
  align-items: center;
  min-height: 50px;
  background-color: var(--deco-light-grey);
  margin-bottom: 5px;
  border-radius: 10px;
  box-shadow: var(--normal-shadow);
  padding: 0 20px;
  top: 0;
  margin: 10px;
  z-index: 1500;
  width: calc(100% - 20px);
}

.root {
  padding-top: 60px;
}

.media-container {
  flex: 1;
  flex-basis: auto;
  padding: 10px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  display: grid;
  align-items: start;
  overflow-y: auto;
}

.media-item-wrapper {
  transition: box-shadow ease 0.25s;
}

.media-item-wrapper:hover {
  box-shadow: var(--normal-shadow);
  opacity: 1;
}

.dropdown-wrapper {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
}

.dropdown-title {
  margin-right: 5px;
  color: var(--font-color);
  font-size: 14px;
}

.dropdown-menu {
  border: none;
  background-color: var(--deco-lightest-grey);
  color: var(--font-color);
  padding: 2px;
  font-size: 14px;
}

.dropdown-menu:focus-visible {
  outline: 0;
}

.media-poster-wrapper img {
  width: 100%;
  height: auto;
  display: block;
}

.media-title {
  font-size: var(--normal-text-size);
  color: var(--font-color);
}

.media-title, .media-score {
  text-align: center;
}

.media-link {
  position: relative;
}

.media-buttons {
  width: 100%;
  position: absolute;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  bottom: 0px;
  transition: opacity ease 0.25s;
  opacity: 0;
}

.media-poster-wrapper:hover .media-buttons {
  opacity: 1;
  pointer-events: auto;
}

.media-buttons > button {
  width: 100%;
  border: none;
  background-color: rgba(0, 0, 0, 0.672);
  color: var(--font-color);
  font-size: var(--normal-text-size);
  padding: 4px;
  transition: background-color ease 0.25s;
}

.media-buttons > button:focus-visible {
  outline: 0;
}

.media-buttons > button:hover {
  background-color: rgba(31, 31, 31, 0.672);
  cursor: pointer;
}

.logout {
  background: transparent;
  border: none;
  text-decoration:none;
}

.sidebar {
  position: fixed;
  top: 0;
  right: -250px;
  width: 250px;
  height: 101%;
  background-color: var(--deco-grey);
  box-shadow: var(--normal-shadow);
  transition: right 0.3s ease;
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.sidebar.is-open {
  right: 0;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sidebar-toggle {
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: var(--font-color);
  margin-left: 0;
  padding-left: 0;
}

@media (max-width: 768px) {
  .bar {
    padding: 0 10px 0 10px;
  }

  .dashboard-container {
    position: relative;
    top: 0;
    margin-top: 0px;
  }

  .search-bar {
    min-width: 10px;
    max-width: 100px;
  }

  .media-container {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }

  .media-buttons {
    display: none;
  }

  .media-item-wrapper:hover {
    box-shadow: none;
  }
}

@media (min-width: 769px) {
  .sidebar {
    display: none;
  }
  .sidebar-toggle {
    display: none;
  }
}

</style>