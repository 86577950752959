<template>
  <div class="root">
    <div class="mediaview-wrapper">
      <div v-if="media" class="main-widget-row">
        <div class="media-poster-wrapper">
          <div class="media-poster">
            <img v-lazy="media.image_url">
          </div>
          <div class="media-score-wrapper">
            <div class="media-vote-up media-vote" @click="vote(media.id, 1)">
              ⇡
            </div>
            <div class="media-vote-counter media-vote">
              {{ media.score }}
            </div>
            <div class="media-vote-down media-vote" @click="vote(media.id, -1)">
              ⇣
            </div>
          </div>
        </div>
        <div class="media-information">
          <div class="media-title">
            {{  media.title }}
          </div>
          <div class="media-short-info">
            <div class="runtime">{{ metadata.VideoDisplayTitle ? runtime : "Series" }}</div>
            <div class="spacer">-</div>
            <div class="rating media-short-info-middle">⭐ {{ metadata.CommunityRating }}</div>
            <div v-if="metadata.VideoDisplayTitle" class="spacer">-</div>
            <div v-if="metadata.VideoDisplayTitle" class="resolution">{{ metadata.VideoDisplayTitle }}</div>
          </div>
          <div class="media-synopsis">
            {{ metadata.Overview }}
          </div>
        </div>
      </div>
      <!-- <div class="mediasources-wrapper">
        <button class="mediasources-button video" v-on:click="collapse($event)">🞂 Video</button>
        <div class="mediasources-text">
          10p 120fps
          fdsfsdfd
        </div>
        <button class="mediasources-button audio" v-on:click="collapse($event)">🞂 Audio</button>
        <div class="mediasources audio">

        </div>
        <button class="mediasources-button subtitles" v-on:click="collapse($event)">🞂 Subtitles</button>
        <div class="mediasources subtitles">

        </div>
      </div> -->
      <div class="comment-box">
        <div class="comment-create-box">
          <textarea class="comment-create-text" v-model="commentText" placeholder="Write a comment..."></textarea>
          <button class="comment-send" @click="new_comment(commentText)">
            Send
          </button>
        </div>
        <div class="border"></div>
        <div class="comment-history">
          <div v-for="(comment, index) in comments" :key="comment.id" :id="index" class="comment">
            <div class="comment-author">
              <div class="comment-author-name">{{ comment.username }}</div>
              <div class="comment-image-border">
                <div class="comment-author-image" :style="{backgroundImage: `url(${getAvatar(comment.avatar)})`}"></div>
              </div>
            </div>
            <div class="comment-box-2-I-guess">
              <div class="comment-bar">
                <div v-if="comment.deleteable" class="comment-delete-button" @click="delete_comment(comment.id)">
                  ❌
                </div>
                <div class="comment-date">
                  {{ comment.created_at }}
                </div>
              </div>
              <div class="comment-text">
                {{ comment.comment_text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import axios from 'axios';

export default {
  data() {
    return {
      media: null,
      metadata: null,
      status: false,
      runtime: null,
      vote_val: 0,
      comments: [],
      default_avatar: "https://cataas.com/cat"
    }
  },
  created() {
    this.fetchData();
    this.get_comments();
  },
  methods: {
    getAvatar(avatar) {
      if (avatar) {
        return avatar;
      }
      return `${this.default_avatar}?cache_buster=${Math.random().toString(36).substring(2, 15)}`;
    },
    delete_comment(id) {
      console.log(id);
      axios.delete(`/api/comments/${id}`, {
        withCredentials: true
      })
      .then(() => {
        this.get_comments();
      })
      .catch((error) => {
        const router = useRouter();
        this.status = true;
        this.dataVar = error.response.data;
        console.log(this.dataVar);
        if (this.dataVar) {
          if (this.dataVar.message.includes("Error: Your session expired")) {
            this.$cookies.remove("session");
            router.push({ name: "LoginForm" });
          }
        }
      })
    },
    new_comment(text) {
      console.log(text);
      const id = this.$route.params.id;
      const params = new URLSearchParams();
      params.append('text', text);
      axios.post(`/api/comments/${id}`, {
        withCredentials: true,
        text: text
      })
      .then(() => {
        this.get_comments();
      })
      .catch((error) => {
        const router = useRouter();
        this.status = true;
        this.dataVar = error.response.data;
        console.log(this.dataVar);
        if (this.dataVar) {
          if (this.dataVar.message.includes("Error: Your session expired")) {
            this.$cookies.remove("session");
            router.push({ name: "LoginForm" });
          }
        }
      })
    },
    get_comments() {
      const id = this.$route.params.id;
      axios.get(`/api/comments/${id}`, {
        withCredentials: true
      })
      .then((data) => {
        this.comments = data.data;
      })
      .catch((error) => {
        const router = useRouter();
        this.status = true;
        this.dataVar = error.response.data;
        if (this.dataVar) {
          if (this.dataVar.message.includes("Error: Your session expired")) {
            this.$cookies.remove("session");
            router.push({ name: "LoginForm" });
          }
        }
      });
    },
    vote(id, val) {
      axios.post(`/api/media/${id}/${val}`, {
        withCredentials: true
      })
      .then(() => {
        this.updateVotes(id);
      })
      .catch((error) => {
        const router = useRouter();
        this.status = true;
        this.dataVar = error.response.data;
        if (this.dataVar) {
          if (this.dataVar.message.includes("Error: Your session expired")) {
            this.$cookies.remove("session");
            router.push({ name: "LoginForm" });
          }
        }
      });
    },
    updateVotes(id) {
      axios.get(`/api/media/${id}/votes`, {
        withCredentials: true
      })
      .then((data) => {
        this.media.score = data.data.val;
      })
      .catch((error) => {
        const router = useRouter();
        this.status = true;
        this.dataVar = error.response.data;
        if (this.dataVar) {
          if (this.dataVar.message.includes("Error: Your session expired")) {
            this.$cookies.remove("session");
            router.push({ name: "LoginForm" });
          }
        }
      })
    },
    convertTime(seconds) {
      seconds = seconds / 10000000;
      const secondsInMinute = 60;
      const secondsInHour = 60 * secondsInMinute;
      const secondsInDay = 24 * secondsInHour;
      const secondsInMonth = 30.44 * secondsInDay;

      const months = Math.floor(seconds / secondsInMonth);
      seconds -= months * secondsInMonth;

      const days = Math.floor(seconds / secondsInDay);
      seconds -= days * secondsInDay;

      const hours = Math.floor(seconds / secondsInHour);
      seconds -= hours * secondsInHour;

      const minutes = Math.floor(seconds / secondsInMinute);

      const timeParts = [];
      if (months > 0) timeParts.push(`${months}m${months > 1 ? '' : ''}`);
      if (days > 0) timeParts.push(`${days}d${days > 1 ? '' : ''}`);
      if (hours > 0) timeParts.push(`${hours}h${hours > 1 ? '' : ''}`);
      if (minutes > 0) timeParts.push(`${minutes}m${minutes > 1 ? '' : ''}`);

      return timeParts.join(' ');
    },
    fetchData() {
      const id = this.$route.params.id;
      axios.get(`/api/media/${id}`, {
        withCredentials: true
      })
      .then((data) => {
        this.media = data.data;
        this.metadata = JSON.parse(data.data.metadata);
        this.runtime = this.convertTime(this.metadata.RunTimeTicks);
        this.status = true;
      })
      .catch((error) => {
        const router = useRouter();
        this.status = true;
        this.dataVar = error.response.data;
        if (this.dataVar) {
          if (this.dataVar.message.includes("Error: Your session expired")) {
            this.$cookies.remove("session");
            router.push({ name: "LoginForm" });
          }
        }
      });
    }
  }
}

</script>

<style scoped>
.root {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 60vw;
}

.mediaview-wrapper {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.main-widget-row {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.media-poster-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.media-information {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 500px;
  padding-left: 20px;
}

.media-title {
  font-size: var(--bigger-text-size);
  font-weight: bold;
  margin-bottom: 8px;
}

.media-short-info {
  font-size: 14px;
  margin-bottom: 8px;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
}

.media-short-info > div {
  font-size: 17px;
}

.spacer {
  padding: 0 10px 0 10px;
}

.media-synopsis {
  font-size: 16px;
  border-radius: 10px;
  background-color: var(--deco-grey);
  padding: 10px;
  margin-top: 10px;
  min-height: 200px;
}

.media-poster img {
  max-width: 400px;
  max-height: 500px;
  width: auto;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: var(--normal-shadow);
}

.media-score-wrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  text-align: center;
}

.mediasources-button {
  text-align: left;
  border: none;
  border-radius: 10px;
  background-color: transparent;
  color: var(--font-color);
  font-size: var(--bigger-text-size);
  transition: background-color 500ms;
}

.mediasources-button:hover {
  background-color: var(--deco-lightest-grey);
}

.mediasources-button:active {
  background-color: var(--deco-grey);
}

.mediasources-wrapper {
  margin-top: 30px;
}

.mediasources-text {
  background-color: var(--deco-grey);
  white-space: pre-line;
  border: 1px solid red;
  display: block;
}

.media-vote {
  flex-grow: 1;
  font-size: var(--bigger-text-size);
  font-weight: 600;
  transition: background-color 500ms;
  border-radius: 5px;
  margin: 2px;
}

.media-vote-up:hover, .media-vote-down:hover {
  background-color: var(--deco-lightest-grey);
  cursor: pointer;
}

.media-vote:active {
  background-color: var(--deco-grey);
}

.comment-box {
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
}

.comment-create-box {
  display: flex;
  flex-direction: row;
}

.comment-create-text {
  padding: 10px;
  flex-grow: 2;
  border: none;
  border-radius: 5px;
  background-color: var(--deco-dark-grey);
  color: var(--font-color);
  margin-right: 10px;
}

.comment-create-text:focus-visible {
  outline: 0
}

.comment-send {
  flex-grow: 1;
  max-width: 100px;
  border: none;
  background-color: var(--deco-light-grey);
  color: var(--font-color);
  border-radius: 3px;
}

.comment-history {
  margin-top: 10px;
}

.comment {
  justify-content: space-around;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.comment-author-name {
  font-weight: bold;
  font-size: 18px;
}

.comment-box-2-I-guess {
  flex-grow: 1;
  width: 100%;
}

.comment-text {
  text-align: left;
  height: 100%;
  padding-top: 10px;
  margin-left: 20px;
}

.comment-author-image {
  width: 150px;
  height: 150px;
  background-size: cover;
  background-position: 50%;
}

.comment-image-border {
  margin-top: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.comment-bar {
  flex-direction: row-reverse;
  display: flex;
}

.comment-delete-button {
  text-align: right;
  color: var(--deco-lightest-grey);
  margin-left: 10px;
}

.comment-delete-button:hover {
  cursor: pointer;
}

.comment-date {
  text-align: right;
  color: var(--deco-lightest-grey);
}

.border {
  background-color: #1c1c1c;
  height: 4px;
  margin-top: 20px;
}

.removerr-title {
  font-size: var(--normal-text-size);
  color: var(--font-color);
  font-weight: 600;
  margin-right: auto;
  align-items: center;
}

.search-bar-wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.search-bar {
  min-width: 10px;
  max-width: 400px;
  padding: 6px;
  background-color: rgb(40, 40, 40);
  border: none;
  border-radius: 3px;
  border-bottom: solid 1px white;
  color: var(--font-color);
  font-size: var(--normal-text-size);
}

.search-bar:focus-visible {
  outline: 0;
  border: none;
}

.dashboard-container {
  background: var(--deco-grey);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
}

* {
  box-sizing: border-box;
}

.bar {
  display: flex;
  position: relative;
  align-items: center;
  min-height: 50px;
  background-color: var(--deco-light-grey);
  margin: 7px;
  border-radius: 10px;
  box-shadow: var(--normal-shadow);
  padding: 0 20px;
}

.media-container {
  flex: 1;
  flex-basis: auto;
  width: 100vw;
  height: 100vh;
  background-color: var(--deco-grey);
  padding: 10px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  display: grid;
  justify-content: center;
  align-items: start;
  max-height: 100vh;
  overflow-y: auto;
}

.media-item-wrapper {
  transition: transform ease 0.25s, box-shadow ease 0.25s;
  padding-bottom: 5px;
}

.media-item-wrapper:hover {
  box-shadow: var(--normal-shadow);
  opacity: 1;
}

.logout {
  background: transparent;
  border: none;
  text-decoration:none;
}

@media (max-width: 768px) {
  .root {
    width: 85vw;
  }

  .comment-author-image {
    width: 100px;
    height: 100px;
  }

  .main-widget-row {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }

  .media-poster-wrapper {
    margin-bottom: 1rem;
  }

  .media-information {
    align-items: center;
    text-align: center;
    padding: 0 10px 0 10px;
    min-width: 100vw;
  }

  .mediaview-wrapper {
    padding-top: 15px;
  }

  .media-short-info {
    justify-content: center;
  }

  .media-synopsis {
    min-height: 100px;
    text-align: left
  }

  .comment-delete-button {
    margin-right: 0;
    margin-left: 10px;
  }
}
</style>